import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { ApiBridgeService } from 'src/app/core/api-bridge.service';
import {
  ApplicationInsightsService,
  EventName,
} from 'src/app/core/application-insights.service';
import { StorageKey, StorageService } from 'src/app/core/storage.service';
import { Feedback, FilterProduct, Product } from './product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private readonly apiBridge: ApiBridgeService,
    private readonly storageService: StorageService,
    private readonly applicationInsightsService: ApplicationInsightsService
  ) {}

  getProducts(clientId: string): Observable<Product[]> {
    return this.apiBridge.get('recommendation/' + clientId).pipe(
      map((response: any) => response as Product[]),
      catchError(() => {
        return of([]);
      }),
      map((products: Product[]) => {
        const sortedProducts = [...products].sort(
          (a, b) => a.sell_priority - b.sell_priority
        );
        return sortedProducts;
      })
    );
  }

  filterProducts(products: Product[], clientId: string): Product[] {
    const filters = this.getFilters() as FilterProduct;
    if (!filters) {
      return products;
    }
    this.applicationInsightsService.logEvent(EventName.FilterProducts, {
      selected_category: filters.selectedCategory
        .map((category) => category.name)
        .toString(),
      selectedType: filters.selectedType.toString(),
      clientId,
    });
    return products.filter((product: Product) => {
      const typeMatch =
        filters.selectedType === 1 ||
        (filters.selectedType === 2 && product.product_type === true) ||
        (filters.selectedType === 3 && product.product_type === false);

      let categoryMatch = false;
      if (filters.selectedCategory.length === 0) {
        categoryMatch = true;
      } else {
        categoryMatch = filters.selectedCategory.some(
          (category) => category.name === product.product_category
        );
      }

      return typeMatch && categoryMatch;
    });
  }

  getProductsFeedback(clientId: string): Observable<Feedback[]> {
    return this.apiBridge.get('recommendation_feedback/' + clientId).pipe(
      map((response) => response as Feedback[]),
      catchError(() => {
        return of([]);
      })
    );
  }

  setFilter(filters: FilterProduct): void {
    this.storageService.saveData(StorageKey.ProductFilter, filters);
  }

  getFilters(): FilterProduct | null {
    let filters: FilterProduct | null = null;
    filters = this.storageService.getData(
      StorageKey.ProductFilter
    ) as FilterProduct;
    return filters;
  }

  postProductsFeedback(
    product: Product,
    selection: boolean | null
  ): Observable<Feedback> {
    return this.apiBridge.post('recommendation_feedback', {
      recommendation_id: product.id,
      state: selection,
    });
  }
}
